import request from '@/request'

/**
 * 列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const jobsList = (params) => {
  return request.get('/jobs', {
    params
  })
}

/**
 * 详情
 * @returns {Promise<AxiosResponse<any>>}
 */
export const jobsInfo = (params) => {
  return request.get('/jobs/info', {
    params
  })
}

/**
 * 创建
 * @returns {Promise<AxiosResponse<any>>}
 */
export const jobsCreate = (data) => {
  return request.post('/jobs/create', data)
}

/**
 * 编辑
 * @returns {Promise<AxiosResponse<any>>}
 */
export const jobsEdit = (data) => {
  return request.post('/jobs/edit', data)
}

/**
 * 删除
 * @returns {Promise<AxiosResponse<any>>}
 */
export const jobsDelete = (data) => {
  return request.post('/jobs/delete', data)
}
